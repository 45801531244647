<template>
  <SimpleLayout>
    <router-view></router-view>
  </SimpleLayout>
</template>

<script>
import SimpleLayout from "@/components/layouts/SimpleLayout";
export default {
  name: "index",
  components: {SimpleLayout}
}
</script>

<style scoped>

</style>